import React from "react";
import "./Map.css";

export default function Map() {
  return (
    <section id="contact" className="contact">
      <div className="row">
        <iframe
          title="Banyuwangi Blue Fire Map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3948.661048761694!2d114.3623767!3d-8.236797699999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd15b931c24915d%3A0xd81de0f3295395bf!2sBlue%20Fire%20Banyuwangi!5e0!3m2!1sid!2sid!4v1692120602288!5m2!1sid!2sid"
          width="600"
          height="450"
          style={{ border: "0" }} 
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          className="map"
        ></iframe>
      </div>
    </section>
  );
}
