import React, {useEffect} from "react";
import "./home.css";
import video from '../../Assets/video.mp4'


import Aos from 'aos'
import 'aos/dist/aos.css'

const Home = () => {
  //  Animation
  useEffect(()=>{
    Aos.init({duration: 1000})
  }, [])

  return (
    <section className="home">
      <div className="overlay"></div>
      <video src={video} muted autoPlay loop type="video/mp4"></video>

      <div className="homeContent container">
        <div className="textDiv">
          <span data-aos="fade-up" className="smallText">
            Melayani dengan Hati
          </span>

          <h1 data-aos="fade-up" className="homeTitle">
            Tentukan Liburan Anda
          </h1>

        </div>
      </div>
    </section>
  );
  
};

export default Home;
