import React from "react";
import ContactInfo from "../ContactInfo/contactInfo";
import Map from "../../Map/map";
import { MdLocalPhone } from "react-icons/md";
import { MdEmail } from "react-icons/md";
import { AiFillInstagram } from "react-icons/ai";
import "./contactSection.css"; // Impor file CSS yang sesuai

function redirectToWhatsapp() {
  window.open("https://api.whatsapp.com/send?phone=6281138234333");
}

function redirectToInstagram() {
  window.open("https://www.instagram.com/bluefirebanyuwangi/");
}
function redirectToMaps() {
  window.open("https://goo.gl/maps/quNReDE4kU53Z2XRA");
}

function redirectToEmail() {
  const email = "banyuwangibluefire@gmail.com";
  const subject = "Pertanyaan mengenai layanan";
  const body = "Halo, saya ingin bertanya tentang layanan yang Anda tawarkan.";

  const emailUrl = `mailto:${email}?subject=${encodeURIComponent(
    subject
  )}&body=${encodeURIComponent(body)}`;

  window.location.href = emailUrl;
}

export default function ContactSection() {
  return (
    <div>
      <div className="contact container section">
        <div className="secTitle">
          <h3 className="title">Kontak Kami</h3>
        </div>
        <div className="contactSection__wrapper">
          <div className="left">
            <ContactInfo
              onClick={redirectToWhatsapp}
              icon={<MdLocalPhone />}
              text="0811-3823-4333"
            />
            <ContactInfo
              onClick={redirectToEmail}
              icon={<MdEmail />}
              text="banyuwangibluefire@gmail.com"
            />
            <ContactInfo
              icon={<AiFillInstagram />}
              onClick={redirectToInstagram}
              text="@bluefirebanyuwangi"
            />
            <ContactInfo
              onClick={redirectToMaps}
              text="Jl.Kepiting No.313, Sobo, Kota Banyuwangi"
            />
          </div>
          <div className="contactSection__divider"></div>
          <div className="right">
            <Map />
          </div>
        </div>
      </div>
    </div>
  );
}
