import React,{useEffect} from "react";
import "./main.css";
import Aos from 'aos'
import 'aos/dist/aos.css'
import { Link } from "react-router-dom";

// import icon 
import {HiOutlineLocationMarker} from 'react-icons/hi'
import {HiOutlineClipboardDocumentCheck} from "react-icons/hi2";


// import pict
import img from '../../Assets/img(1).jpeg'
import img2 from "../../Assets/img(2).jpeg";
import img3 from "../../Assets/img(3).jpeg";
import img4 from "../../Assets/img(4).jpeg";
import img5 from "../../Assets/img(5).jpeg";
import img6 from "../../Assets/img(6).jpeg";
import img7 from "../../Assets/img(7).jpeg";



const Data = [
  {
    id: 1,
    imgSrc: img,
    slug: "trip-ijen",
    destTitle: "Kawah Ijen",
    location: "Banyuwangi",
    grade: "Pemandangan Blue Fire",
    fees: "Rp.230.000,",
    description:
      "Api biru di Kawah Ijen adalah fenomena alam yang memukau yang terjadi di dalam kompleks Gunung Api Ijen, yang terletak di Jawa Timur, Indonesia. Kawah Ijen adalah kawah vulkanik yang mengandung salah satu danau kawah asam terbesar di dunia, dikenal sebagai Kawah Ijen atau Danau Ijen.",
  },
  {
    id: 2,
    imgSrc: img2,
    slug: "de-djawatan",
    destTitle: "Pulau Merah",
    location: "Banyuwangi",
    grade: "Senja yang sempurna",
    fees: "Rp.255.000,",
    description:
      'Pulau Merah merupakan destinasi wisata yang indah yang terletak di Banyuwangi, Jawa Timur, Indonesia. Nama "Pulau Merah" berasal dari pantai berpasir merahnya, yang menambah daya tarik unik pada pulau tersebut. Pulau Merah terletak sekitar 60 kilometer dari pusat kota Banyuwangi dan dapat dicapai dalam waktu perjalanan sekitar 1,5 - 2 jam dari pusat kota.',
  },
  {
    id: 3,
    imgSrc: img3,
    slug: "tour-alas-purwo",
    destTitle: "Alas Purwo",
    location: "Banyuwangi",
    grade: "Pemandangan Pantai & Hutan",
    fees: "Rp.275.000,",
    description:
      "Taman Nasional Alas Purwo adalah kawasan lindung yang terletak di bagian timur Jawa, Indonesia, tepatnya di Kabupaten Banyuwangi, Provinsi Jawa Timur. Ini merupakan salah satu taman nasional tertua dan paling signifikan di Indonesia, dikenal karena keanekaragaman hayati yang kaya, ekosistem unik, dan makna budayanya.",
  },
  {
    id: 4,
    imgSrc: img4,
    slug: "de-djawatan",
    destTitle: "De Djawatan",
    location: "Banyuwangi",
    grade: "Hutan Negeri Dongeng",
    fees: "Rp.255.000,",
    description:
      "De Djawatan adalah tujuan wisata yang sebenarnya merupakan kawasan hutan lindung yang dimiliki oleh Perhutani. Tempat wisata ini menawarkan pemandangan indah dengan udara yang sejuk, menjadikannya pilihan yang aman dan nyaman bagi para wisatawan.",
  },
  {
    id: 5,
    imgSrc: img5,
    destTitle: "GreenBay(Teluk Hijau)",
    slug: "de-djawatan",
    location: "Banyuwangi",
    grade: "Air berwarna Turquoise",
    fees: "Rp.255.000,",
    description:
      "Green Bay, yang juga dikenal sebagai Teluk Hijau, adalah permata tersembunyi yang menakjubkan yang terletak di Banyuwangi, Jawa Timur, Indonesia. Ini adalah pantai terpencil yang tersembunyi di tengah hutan lebat, menjadikannya tujuan yang benar-benar alami dan indah bagi pecinta alam dan pencari petualangan.",
  },
  {
    id: 6,
    imgSrc: img6,
    destTitle: "Desa Osing",
    slug: "tour-kota-banyuwangi",
    location: "Banyuwangi",
    grade: "Seni & Budaya Tradisional",
    fees: "Rp.290.000,",
    description:
      "Desa Osing, atau Desa Osing, adalah desa budaya unik yang terletak di kabupaten Banyuwangi, Jawa Timur, Indonesia. Desa ini dihuni oleh kelompok etnis Osing, yang telah mempertahankan budaya, tradisi, dan gaya hidup khas mereka selama berabad-abad.",
  },
  {
    id: 7,
    imgSrc: img7,
    destTitle: "Tabuhan Island",
    slug: "menjangan-tabuhan",
    location: "Banyuwangi",
    grade: "Snorkeling & Diving",
    fees: "Rp.300.000,",
    description:
      "Pulau Tabuhan, juga dikenal sebagai Tabuhan Island, adalah sebuah pulau cantik yang terletak di lepas pantai Banyuwangi, Jawa Timur, Indonesia. Pulau ini merupakan pulau kecil yang indah dan telah menjadi tujuan populer bagi wisatawan yang mencari tempat peristirahatan yang tenang dan tropis.",
  },
];

const Main = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);



  
  return (
    <section className="main container section">
      <div className="secTitle">
        <h3 data-aos="fade-right" className="title">
          Destinasi Populer
        </h3>
      </div>

      <div className="setContent grid">
        {Data.map(
          ({ id, imgSrc, destTitle, location, grade, fees, description, slug}) => {
            return (
              <div key={id} className="singleDestination">
                <div data-aos="fade-up" className="imageDiv">
                  <img src={imgSrc} alt={destTitle} />
                </div>

                <div data-aos="fade-up" className="cardInfo">
                  <h4 className="destTitle">{destTitle}</h4>
                  <span className="continent flex">
                    <HiOutlineLocationMarker className="icon" />
                    <span className="name">{location}</span>
                  </span>

                  <div className="fees flex">
                    <div className="grade">
                      <span>
                        {grade}
                        <small>+1</small>
                      </span>
                    </div>

                    <div className="price">
                      <h5>{fees}</h5>
                    </div>
                  </div>

                  <div className="des">
                    <p>{description}</p>
                  </div>

                  <Link to={`/Paket/${slug}`}>
                    <button className="btn flex">
                      DETAILS{" "}
                      <HiOutlineClipboardDocumentCheck className="icon" />
                    </button>
                  </Link>
                </div>
              </div>
            );
          }
        )}
      </div>
    </section>
  );
}

export default Main;
