// import Home from "../Components/Home/Home";
import Navbar from "../Components/Navbar/Navbar";
import Contact from "../Components/Contact/ContactSection/contactSection";

import Footer from "../Components/Footer/Footer";

// import { MdPlace } from "react-icons/md";

function contact() {
  return (
    <>
      <Navbar />
      <Contact />
      <Footer />
    </>
  );
}

export default contact;
