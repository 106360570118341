import React, { useEffect } from "react";
import "./footer.css";
import video2 from "../../Assets/video2.mp4";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { AiOutlineMail } from "react-icons/ai";
import { GrMap } from "react-icons/gr";
import { GrInstagram } from "react-icons/gr";
import { FiChevronRight } from "react-icons/fi";
import Aos from "aos";
import "aos/dist/aos.css";
import iconTravel from "../../Assets/Image/iconTravelNavbar.png";

function redirectToWhatsapp() {
  window.open("https://api.whatsapp.com/send?phone=6281138234333", "_blank");
}

function redirectToInstagram() {
  window.open("https://www.instagram.com/bluefirebanyuwangi/");
}
function redirectToMaps() {
  window.open("https://goo.gl/maps/quNReDE4kU53Z2XRA");
}

function redirectToEmail() {
  const email = "banyuwangibluefire@gmail.com";
  const subject = "Pertanyaan mengenai layanan";
  const body = "Halo, saya ingin bertanya tentang layanan yang Anda tawarkan.";

  const emailUrl = `mailto:${email}?subject=${encodeURIComponent(
    subject
  )}&body=${encodeURIComponent(body)}`;

  window.location.href = emailUrl;
}

const Footer = () => {
  useEffect(() => {
    Aos.init({ duration: 900 });
  }, []);

  return (
    <section className="footer">
      <div className="videDiv">
        <video src={video2} loop autoPlay muted type="video/mp4"></video>
      </div>

      <div data-aos="fade-up" className="secContent container">
        <div className="contactDiv flex">
          <div className="text">
            <small>Serahkan pada Kami</small>
            <h2> Perjalanan Wisata Anda</h2>
          </div>
        </div>

        <div className="footerCard flex">
          <div className="footerIntro flex">
            <div data-aos="fade-up" className="logoDiv">
              <a href="/" className="logo flex">
                <img src={iconTravel} alt="Icon Travel" className="icon-size" />
                <span className="logoText">BlueFire</span>
                <span className="spanLogo">Banyuwangi</span>
              </a>
            </div>

            <div data-aos="fade-up" className="footerParagraph">
              Selamat datang di Blue Fire Banyuwangi, perusahaan tour and travel
              yang berlokasi di Kota Banyuwangi, tempatnya petualangan dan
              keajaiban alam yang mengagumkan! Kami adalah mitra perjalanan
              terpercaya Anda untuk menjelajahi pesona Banyuwangi dan sekitarnya
              dengan cara yang menyenangkan, aman, dan tak terlupakan.
            </div>

            <div data-aos="fade-up" className="footerSocials flex">
              <AiOutlineWhatsApp
                className="icon"
                onClick={redirectToWhatsapp}
                target="_blank"
              />
              <AiOutlineMail
                className="icon"
                onClick={redirectToEmail}
                target="_blank"
              />
              <GrMap
                className="icon"
                onClick={redirectToMaps}
                target="_blank"
              />
              <GrInstagram
                className="icon"
                onClick={redirectToInstagram}
                target="_blank"
              />
            </div>
          </div>

          <div data-aos="fade-up" className="footerLinks grid">
            {/* Group One */}
            <div className="linkGroup">
              <span className="groupTitle">Layanan Kami</span>

              <li className="footerList flex">
                <FiChevronRight className="icon" />
                Agen Wisata
              </li>

              <li className="footerList flex">
                <FiChevronRight className="icon" />
                Penukaran Uang
              </li>

              <li className="footerList flex">
                <FiChevronRight className="icon" />
                Asuransi
              </li>

              <li className="footerList flex">
                <FiChevronRight className="icon" />
                Pariwisata
              </li>
            </div>
          </div>

          <div data-aos="fade-up" className="footerDiv flex">
            <small>Website Travel Terbaik</small>
            <small>Copyright Reserved - Nant's Project 2023</small>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
