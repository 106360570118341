import Navbar from "../Components/Navbar/Navbar";
import About from "../Components/About/about";
import Footer from "../Components/Footer/Footer";

function about() {
  return (
    <>
      {/* <h1>This is About</h1> */}
      <Navbar />
      <About />
      <Footer />
    </>
  );
}

export default about;
