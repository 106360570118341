import Navbar from "../Components/Navbar/Navbar";
import Service from "../Components/Paket/ServiceSection";
import Footer from "../Components/Footer/Footer"

function service() {
  return (
    <>
      <Navbar />
      <Service />
      <Footer />
    </>
  );
}

export default service;
