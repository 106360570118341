import React, { useEffect } from "react";
import "./about.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

// import icon
import { HiOutlineLocationMarker } from "react-icons/hi";
import { HiOutlineClipboardDocumentCheck } from "react-icons/hi2";

// import pict
import img from "../../Assets/img(1).jpeg";
import img2 from "../../Assets/img(2).jpeg";
import img3 from "../../Assets/img(3).jpeg";
import img4 from "../../Assets/img(4).jpeg";
import img5 from "../../Assets/img(5).jpeg";
import img6 from "../../Assets/img(6).jpeg";
import img7 from "../../Assets/img(7).jpeg";
import img8 from "../../Assets/img(8).jpeg";
import img9 from "../../Assets/img(9).jpeg";
import img10 from "../../Assets/imgP(11).jpeg";
import img11 from "../../Assets/imgP(12).jpeg";
import img12 from "../../Assets/imgP(13).jpeg";

const Data = [
  {
    id: 1,
    imgSrc: img,
    destTitle: "Kawah Ijen",
    slug: "trip-ijen",
    location: "Banyuwangi",
    grade: "Pemandangan Blue Fire",
    fees: "Rp.230.000,",
    description:
      "Api biru di Kawah Ijen adalah fenomena alam yang memukau yang terjadi di dalam kompleks Gunung Ijen, yang terletak di Jawa Timur, Indonesia. Kawah Ijen adalah kawah gunung berapi yang mengandung salah satu danau kawah asam terbesar di dunia, dikenal sebagai Kawah Ijen atau Danau Ijen.",
  },
  {
    id: 2,
    imgSrc: img2,
    destTitle: "Pulau Merah",
    slug: "de-djawatan",
    location: "Banyuwangi",
    grade: "Senja yang sempurna",
    fees: "Rp.255.000,",
    description:
      'Pulau Merah merupakan destinasi wisata yang indah yang terletak di Banyuwangi, Jawa Timur, Indonesia. Nama "Pulau Merah" berasal dari pantai berpasir merahnya yang memberikan daya tarik unik pada pulau ini. Pulau Merah terletak sekitar 60 kilometer dari pusat kota Banyuwangi dan dapat dicapai dalam waktu perjalanan sekitar 1,5 - 2 jam dari pusat kota.',
  },
  {
    id: 3,
    imgSrc: img3,
    destTitle: "Alas Purwo",
    slug: "tour-alas-purwo",
    location: "Banyuwangi",
    grade: "Pemandangan Pantai & Hutan",
    fees: "Rp.275.000,",
    description:
      "Taman Nasional Alas Purwo adalah area yang dilindungi yang terletak di bagian timur Jawa, Indonesia, secara khusus di Kabupaten Banyuwangi, Provinsi Jawa Timur. Ini adalah salah satu taman nasional tertua dan paling penting di Indonesia, dikenal karena keanekaragaman hayati yang kaya, ekosistem unik, dan signifikansi budayanya.",
  },
  {
    id: 4,
    imgSrc: img4,
    destTitle: "De Djawatan",
    location: "Banyuwangi",
    slug: "de-djawatan",
    grade: "Hutan Negeri Dongeng",
    fees: "Rp.255.000,",
    description:
      "De Djawatan adalah tujuan wisata yang sebenarnya merupakan area hutan lindung yang dimiliki oleh Perhutani. Tempat wisata ini menawarkan pemandangan indah dengan udara sejuk, membuatnya menjadi pilihan yang aman dan nyaman bagi para wisatawan.",
  },
  {
    id: 5,
    imgSrc: img5,
    slug: "de-djawatan",
    destTitle: "GreenBay(Teluk Hijau)",
    location: "Banyuwangi",
    grade: "Air berwarna Turquoise",
    fees: "Rp.255.000,",
    description:
      "Green Bay, yang juga dikenal sebagai Teluk Hijau, adalah permata yang menakjubkan dan tersembunyi yang terletak di Banyuwangi, Jawa Timur, Indonesia. Ini adalah pantai yang terpencil yang terletak di tengah hutan lebat, menjadikannya tujuan yang benar-benar asli dan indah bagi para pecinta alam dan pencari petualangan.",
  },
  {
    id: 6,
    imgSrc: img6,
    destTitle: "Desa Osing",
    slug: "tour-kota-banyuwangi",
    location: "Banyuwangi",
    grade: "Seni & Budaya Tradisional",
    fees: "Rp.290.000,",
    description:
      "Desa Osing, atau dikenal juga sebagai Desa Osing, adalah sebuah desa budaya unik yang terletak di kabupaten Banyuwangi, Jawa Timur, Indonesia. Desa ini dihuni oleh kelompok etnis Osing, yang telah menjaga budaya, tradisi, dan gaya hidup khas mereka selama beberapa generasi.",
  },
  {
    id: 7,
    imgSrc: img7,
    destTitle: "Tabuhan Island",
    slug: "menjangan-tabuhan",
    location: "Banyuwangi",
    grade: "Snorkeling & Diving",
    fees: "Rp.300.000,",
    description:
      "Pulau Tabuhan, juga dikenal sebagai Tabuhan Island, adalah sebuah pulau indah yang terletak di lepas pantai Banyuwangi, Jawa Timur, Indonesia. Pulau ini merupakan sebuah pulau kecil yang indah dan pitoresk, yang telah menjadi tujuan populer bagi wisatawan yang mencari tempat pelarian yang tenang dan tropis.",
  },
  {
    id: 8,
    imgSrc: img8,
    destTitle: "Plengkung Beach",
    slug: "tour-alas-purwo",
    location: "Banyuwangi",
    grade: "Tempat Surfing yang Sempurna",
    fees: "Rp.275.000,",
    description:
      "Plengkung Beach, yang juga dikenal sebagai Pantai G-Land, adalah sebuah pantai terkenal yang terletak di ujung timur Pulau Jawa, di Banyuwangi, Jawa Timur, Indonesia. Pantai ini merupakan salah satu destinasi selancar terbaik di dunia dan telah menarik para peselancar dari berbagai penjuru dunia. Pantai Plengkung terkenal dengan gelombangnya yang kuat dan konsisten, menjadikannya surga bagi para peselancar.",
  },
  {
    id: 9,
    imgSrc: img9,
    destTitle: "Baluran National Park",
    slug: "tour-baluran",
    location: "Banyuwangi",
    grade: "Flora & Fauna yang Menakjubkan",
    fees: "Rp.230.000,",
    description:
      "Taman Nasional Baluran adalah kawasan perlindungan yang terletak di Jawa Timur, Indonesia. Taman ini sering disebut sebagai Savannah Afrika Jawa karena lanskap uniknya yang menyerupai savana di Afrika. Taman ini memiliki luas sekitar 25.000 hektar dan terletak di bagian timur laut Jawa, dekat kota Banyuwangi.",
  },
  {
    id: 10,
    imgSrc: img10,
    slug: "tour-kota-banyuwangi",
    destTitle: "Pendopo Banyuwangi",
    location: "Banyuwangi",
    grade: "Kota yang Sempurna",
    fees: "Rp.290.000,",
    description:
      "Pendopo Banyuwangi adalah landmark budaya dan sejarah yang terletak di Kabupaten Banyuwangi, Jawa Timur, Indonesia. Pendopo adalah struktur arsitektur tradisional Jawa yang berfungsi sebagai tempat berkumpul, balai pertemuan, dan pusat budaya. Pendopo Banyuwangi adalah situs budaya dan warisan penting yang memamerkan arsitektur tradisional, seni, dan sejarah daerah tersebut.",
  },
  {
    id: 11,
    imgSrc: img11,
    slug: "tour-kota-banyuwangi",
    destTitle: "Jagir Waterfall",
    location: "Air Terjun yang Sempurna",
    grade: "Perfect Waterfall",
    fees: "Rp.290.000,",
    description:
      "Ketika mengunjungi Air Terjun Jagir, penting untuk menghormati lingkungan alam dan mengikuti panduan yang ditetapkan oleh otoritas lokal untuk memastikan pelestarian permata alam ini. Meskipun mungkin tidak sepopuler beberapa air terjun terkenal lainnya di Indonesia, Air Terjun Jagir menawarkan pengalaman yang tenang dan mendalam bagi mereka yang menghargai keindahan alam yang asli.",
  },
  {
    id: 12,
    imgSrc: img12,
    slug: "tour-kota-banyuwangi",
    destTitle: "Boom Beach",
    location: "Banyuwangi",
    grade: "Eksotis & Memikat",
    fees: "Rp.290.000,",
    description:
      "Terletak di desa Mandar, Banyuwangi, Pantai Boom dulunya adalah pelabuhan penting di Banyuwangi. Pantai ini terletak di tengah kota Banyuwangi dan dikunjungi oleh banyak wisatawan, baik dari lokal maupun dari luar Banyuwangi. Pantai ini memiliki pasir hitam yang khas dan latar belakang eksotis dari pulau Bali. Para pengunjung dapat menikmati pantai sambil menikmati kelapa di sepanjang jalur pejalan kaki atau menikmati naik kuda atau ATV.",
  },
];

const About = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <section className="about container section">
      <div className="secTitle">
        <h3 data-aos="fade-right" className="title">
          Semua Destinasi
        </h3>
      </div>

      <div className="setContent grid">
        {Data.map(
          ({
            id,
            imgSrc,
            destTitle,
            location,
            grade,
            fees,
            description,
            slug,
          }) => {
            return (
              <div key={id} className="singleDestination">
                <div data-aos="fade-up" className="imageDiv">
                  <img src={imgSrc} alt={destTitle} />
                </div>

                <div className="cardInfo">
                  <h4 className="destTitle">{destTitle}</h4>
                  <span className="continent flex">
                    <HiOutlineLocationMarker className="icon" />
                    <span className="name">{location}</span>
                  </span>

                  <div className="fees flex">
                    <div className="grade">
                      <span>
                        {grade}
                        <small>+1</small>
                      </span>
                    </div>

                    <div className="price">
                      <h5>{fees}</h5>
                    </div>
                  </div>

                  <div className="des">
                    <p>{description}</p>
                  </div>

                  <Link to={`/Paket/${slug}`}>
                    <button className="btn flex">
                      DETAILS{" "}
                      <HiOutlineClipboardDocumentCheck className="icon" />
                    </button>
                  </Link>
                </div>
              </div>
            );
          }
        )}
      </div>
    </section>
  );
};

export default About;
